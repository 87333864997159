import Vue from 'vue';
import Component from 'vue-class-component';
import { ApiServices } from '@/services/api.services';
import { DUNATI_DOMAIN, ROBOT_CONTENT } from '@/main';

const components: any = {};

if (typeof window !== 'undefined') {
  // @ts-ignore
  const InvisibleRecaptcha = () => import('vue-invisible-recaptcha');
  components['invisible-recaptcha'] = InvisibleRecaptcha;
}

@Component({
  components,
})
export default class ContactPageComponent extends Vue {
  public data = {
    name: '',
    email: '',
    phone: '',
    message: '',
  };

  public emailSend = false;
  public sendingEmail = false;
  public loading = false;
  public errorEmail = false;
  public sendText = 'Enviar';
  public errorMsg = '';
  public privateKey = '6LchoMEUAAAAAN2Aqiy__h6yfNMXFt2-9Nu7R2pB';

  constructor() {
    super();
    this.$options.dunatiHeadTags = {
      title: 'Contacto Dunati | Muebles de oficina',
      metaTags: [
        /* Primaty Tags */
        {
          name: 'description',
          content: 'Ofrecemos soluciones innovadoras en mobiliario para oficinas. Asesoría, diseño e implementación de espacios de trabajo. Hacemos con pasión Muebles de Oficina.',
        },
        {
          name: 'robot',
          content: ROBOT_CONTENT,
        },
        /* Open Graph / Facebook */
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: `${DUNATI_DOMAIN}/contacto`,
        },
        {
          property: 'og:title',
          content: 'Contacto Dunati | Muebles de oficina',
        },
        {
          property: 'og:description',
          content: 'Ofrecemos soluciones innovadoras en mobiliario para oficinas. Asesoría, diseño e implementación de espacios de trabajo. Hacemos con pasión Muebles de Oficina.',
        },
        {
          property: 'og:image',
          content: `${DUNATI_DOMAIN}/assets/images/home_page/og_home.jpg`,
        },
        /* Twitter */
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: `${DUNATI_DOMAIN}/contacto`,
        },
      ],
      /*
      metaInfo: {
        link: [
          {
            rel: 'canonical',
            href: `${DUNATI_DOMAIN}/contacto`
          }
        ]
      }
      */
    };
  }

  public mounted() {
    this.$store.dispatch('changeMenu', 'bg1');
  }

  public beforeDestroy() {
    this.$store.dispatch('changeMenu', 'normal');
  }

  public titleCase(str: string) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  public sendMail() {
    if (this.data.name !== '' && this.data.message !== '' && this.data.email !== '' && this.validEmail(this.data.email)) {
      this.sendingEmail = true;
      this.loading = true;
      const msg = `<h3>Solicitud de contacto</h3>
      <p>De: ${this.titleCase(this.data.name)}</p>
      <p>Correo: ${this.data.email}</p>
      <p>Teléfono: ${this.data.phone || 'NA'}</p>
      <br>
      <p>${this.data.message}</p>`;

      let conversionUrl = '';
      if (typeof window !== 'undefined') {
        conversionUrl = window.entryUrl;
      }

      const body = {
        notificationsFrom: `${this.titleCase(this.data.name)}`,
        msg,
        subject: `Solicitud de contacto de: ${this.titleCase(this.data.name)}`,
        contact: {
          email: this.data.email,
          phone: this.data.phone,
          message: this.data.message,
          name: this.titleCase(this.data.name),
          source: `contacto`,
          conversionUrl,
        },
      };

      ApiServices.sendMail(body).then((response) => {
        if (response) {
          ApiServices.contactCliengo({
            name: this.titleCase(this.data.name),
            message: this.data.message,
            phone: this.data.phone,
            email: this.data.email,
          }).then((res) => {
            console.log(res);
          }).catch((error) => {
            console.log(error);
          });

          this.data = {
            name: '',
            email: '',
            phone: '',
            message: '',
          };
          this.sendText = '¡Listo!';
          this.emailSend = true;
          this.sendingEmail = false;
          this.loading = false;
        }
      });
    } else {
      this.errorEmail = true;
      if (!this.validEmail(this.data.email)) {
        this.errorMsg = 'El correo electrónico debe ser válido.';
      } else if (this.data.name === '') {
        this.errorMsg = 'El campo nombre es requerido.';
      } else if (this.data.email === '') {
        this.errorMsg = 'El campo correo es requerido.';
      } else if (this.data.message === '') {
        this.errorMsg = 'El campo mensaje es requerido.';
      }

      setTimeout(() => {
        this.errorEmail = false;
        this.errorMsg = '';
      }, 20000);

    }
  }

  public validEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  public callbackTest(recaptchaToken: any) {
    if (recaptchaToken) {
      this.sendMail();
    }
  }
}

